import React from "react";
import { Wrapper, BookButton, Tel } from "./RightHeader.styles";
import { FaPhoneAlt } from 'react-icons/fa';
import MobileMenuButton from "components/molecules/MobileMenuButton/MobileMenuButton";

const RightHeader = ({ openMobileMenu }) => (
  <Wrapper>
    <Tel href="tel:+447919028613">
      <FaPhoneAlt />
      +44 7919028613
    </Tel>
    <BookButton
      target="_blank"
      href="https://www.facebook.com/HaidresserStokeonTrent"
    >
      Book appoitment
    </BookButton>
    <MobileMenuButton onClick={openMobileMenu} />
  </Wrapper>
);

export default RightHeader;
